import React from "react"
import { Router } from "@reach/router"
import {
  logoutCallback,
  loginCallback,
  loginSilentCallback,
  clearStale,
} from "@services/auth"

import Loading from "@components/Loading"

const isBrowser = () => {
  return typeof window !== "undefined"
}

const SilentCallback = () => {
  if (isBrowser()) {
    loginSilentCallback()
  }
  return <Loading />
}

const LoginCallback = () => {
  if (isBrowser()) {
    loginCallback()
  }
  return <Loading />
}

const LogoutCallback = () => {
  if (isBrowser()) {
    clearStale()
    logoutCallback()
  }
  return <Loading />
}

const Authentication = () => {
  return (
    <div>
      <Router basepath="/authentication">
        <LoginCallback path="/login-callback" />
        <LogoutCallback path="/logout-callback" />
        <SilentCallback path="/silent-callback" />
      </Router>
    </div>
  )
}
export default Authentication
